<template>
  <v-expansion-panel style="box-shadow: none;">
    <v-form ref="form" lazy-validation>
      <v-expansion-panel-header class="headline">
        <div>
          <span>{{ $tc('label.apuracao', 1)}}</span>
          <popover-lista id="popover_apuracao" v-if="exibirPopover" :objeto="acaoFormatada" :campos="listaCamposPopover()"/>
          <v-tooltip v-if="possuiMemoriaCalculo" top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :disabled="bloquearBotaoExportacao" @click.stop="verificarExistenciaNotas">notes</v-icon>
            </template>
            <span>{{ $t('message.visualizar_notas_clique_aqui') }}</span>
          </v-tooltip>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <card-info :campos="listaCampos()" v-if="getProdutoMetadado" :objeto="acaoFormatada">
          <template slot="antes">
            <v-col cols="12" sm="6" md="3" class="pl-0" v-if="acao.metas">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="400"
                bottom
                offset-y>
                <template v-slot:activator="{ on }">
                  <v-select
                    v-model="item"
                    :items="items"
                    append-icon
                    class="select--sem--linha"
                    :label="$tc('label.meta', 1)"
                    multiple
                    readonly>
                    <template v-slot:selection="data">
                      <v-icon v-on="on">{{ data.item }}</v-icon>
                    </template>
                  </v-select>
                </template>

                <v-card>
                  <v-data-table
                    :headers="headers()"
                    :items="acao.metas"
                    hide-default-footer
                    disable-pagination
                    :items-per-page="-1"
                    :no-data-text="$t('label.tabela_sem_conteudo')">
                    <template v-slot:item.meta_de="{ item }">
                      <span v-text="exibirMeta(item.vlrMetaDe)"></span>
                    </template>
                    <template v-slot:item.meta_ate="{ item }">
                      <span v-if="item.vlrMetaAte" v-text="exibirMeta(item.vlrMetaAte)"></span>
                    </template>
                    <template v-slot:item.bonificacao_pagamento="{ item }">
                      <span v-text="exibirPagamento(item.vlrBonificacao)"></span>
                    </template>
                  </v-data-table>
                </v-card>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="3" class="pl-0" v-if="acao.meta">
              <input-money
                v-if="calculoValor && !unidadeQuantidade"
                :label="$tc('label.meta', 1)"
                v-model="acao.meta"
                class="v-text-field"
                disabled
                :hint="String(acaoFormatada.valorRealizadoOriginal)"
                :persistent-hint="true"/>
              <v-text-field
                v-else-if="calculoVolume && !unidadeQuantidade"
                :label="$tc('label.meta', 1)"
                v-model="acao.meta"
                type="Number"
                min="0"
                :suffix="acao.siglaUnidadeMedida"
                disabled
                :hint="String(acaoFormatada.valorRealizadoOriginal)"
                :persistent-hint="true"
              ></v-text-field>
              <v-text-field
                v-else-if="calculoPercentual && !unidadeQuantidade"
                :label="$tc('label.meta', 1)"
                v-model="acao.meta"
                type="Number"
                min="0"
                suffix="%"
                disabled
                :hint="String(acaoFormatada.valorRealizadoOriginal)"
                :persistent-hint="true"
              ></v-text-field>
              <v-text-field
                v-else
                :label="$tc('label.meta', 1)"
                v-model="acao.meta"
                type="Number"
                min="0"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3" class="pl-0" v-if="acao.meta">
              <input-money
                :label="$tc('label.valor', 1)"
                v-model="acao.valorBonificacao"
                class="v-text-field"
                disabled
                :persistent-hint="true"/>
            </v-col>

            <v-col cols="12" sm="6" md="3" class="pl-0">
              <input-money
                v-if="calculoValor && !unidadeQuantidade"
                :label="$tc('label.realizado', 1)"
                v-model="acao.valorRealizado"
                class="v-text-field"
                :disabled="!podeAlterarVlrRealizado || somenteLeitura"
                :hint="String(acaoFormatada.valorRealizadoOriginal)"
                :persistent-hint="true"
                @change="alterarValorRecebimento(acao)"/>
              <v-text-field
                v-else-if="calculoVolume && !unidadeQuantidade"
                :label="$tc('label.realizado', 1)"
                v-model="acao.valorRealizado"
                type="Number"
                min="0"
                :suffix="acao.siglaUnidadeMedida"
                :disabled="!podeAlterarVlrRealizado || somenteLeitura"
                :hint="String(acaoFormatada.valorRealizadoOriginal)"
                :persistent-hint="true"
                @change="alterarValorRecebimento"
              ></v-text-field>
              <v-text-field
                v-else-if="calculoPercentual && !unidadeQuantidade"
                :label="$tc('label.realizado', 1)"
                v-model="acao.valorRealizado"
                type="Number"
                min="0"
                suffix="%"
                :disabled="!podeAlterarVlrRealizado || somenteLeitura"
                :hint="String(acaoFormatada.valorRealizadoOriginal)"
                :persistent-hint="true"
                @change="alterarValorRecebimento"
              ></v-text-field>
              <v-text-field
                v-else
                :label="$tc('label.realizado', 1)"
                :rules="[rules.required]"
                v-model="acao.valorRealizado"
                type="Number"
                min="0"
                :hint="String(acaoFormatada.valorRealizadoOriginal)"
                :disabled="!podeAlterarVlrRealizado || somenteLeitura"
                @change="alterarValorRecebimento"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="3" class="pl-0" style="position:relative">
              <input-money
                :id="`valor_recebimento_${acao.idAcao}`"
                :label="$tc('label.valor_recebimento', 1)"
                v-model="acao.vlrConfApuracao"
                class="v-text-field"
                @input="mudancaJustificativaAlteracaoValor"
                :disabled="!podeAlterarVlrPgto || acao.isFluxoParalelo || calculoAutomatico || somenteLeitura"
                :hint="String(acaoFormatada.vlrConfApuracaoOriginal)"
                :persistent-hint="true"/>
              <v-btn
                v-if="exibeIconeRateio"
                icon
                style="position: absolute;right: 10px;top: 20px;"
                @click="exibeRateioValores">
                <v-icon>visibility</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="4" sm="3" v-if="possuiCoeficienteCalculo && coeficienteCalculo !== null">
              <v-text-field
                id="valorBase"
                type="number"
                :disabled="true"
                :label="`${$tc('label.valor_base', 1)}`"
                v-model="coeficienteCalculo">
              </v-text-field>
            </v-col>

            <v-col cols="14" sm="6" md="3" class="pl-0" style="position:relative" v-if="habilitarJustificativa || justificativaAlteracaoValorPagamento">
              <v-textarea
                id="ApuracaoAcaoDetalhesApuracao_justificativa"
                name="ApuracaoAcaoDetalhesApuracao_justificativa"
                ref="ApuracaoAcaoDetalhesApuracao_justificativa"
                :value="justificativaAlteracaoValorPagamento"
                rows="1"
                auto-grow
                @input="mudancaJustificativa($event)"
                :rules="[rules.required]"
                :disabled="!podeAlterarValores || !habilitarJustificativa || somenteLeitura"
                :label="`${$t('label.justificativa_alteracao_valor_recebimento')} *`"
                :counter="500"
                maxlength="500">
              </v-textarea>
            </v-col>

          </template>
        </card-info>

        <apuracao-acao-detalhes-apuracao-rateio
          ref="modalRateio"
          v-if="acao"
          :id-apuracao="recuperaId(acao.idApuracao)"
          :vlr-conf-apuracao="acao.vlrConfApuracao"
          :vlr-reservado="acao.vlrReservado"
          :status-apuracao="acao.statusApuracao"
          :tipos-verbas="acao.tiposVerbas"
          :periodos-orcamentarios="acao.periodosOrcamentarios"
          :somente-leitura="!podeAlterarValores || acao.isFluxoParalelo"
        />
      </v-expansion-panel-content>
    </v-form>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CardInfo from '@/produto/shared-components/CardInfo';
import PopoverLista from '@/produto/shared-components/PopoverLista';
import InputMoney from '@/produto/shared-components/inputs/InputMoney';
import {
  convertToMoney,
  convertToPercent,
  convertToNumberFormat,
  capitalize,
  getMoney,
} from '@/produto/common/functions/helpers';
import { numberBetweenValues } from '@/produto/common/functions/numeric';
import { generateComputed } from '@/produto/common/functions/roles-computed-generator';
import exportacao from '@/produto/common/functions/exportacao';
import ApuracaoAcaoDetalhesApuracaoRateio from '@/produto/spa/apuracao-acao/ApuracaoAcaoDetalhesApuracaoRateio';
import { formatDateDDMMYYYY } from '@/produto/common/functions/date-utils';

export default {
  props: {
    acao: Object,
    configuracao: Object,
    justificativaAlteracaoValorPagamento: String,
    isContrato: {
      type: Boolean,
      default: false,
    },
    possuiCoeficienteCalculo: {
      type: Boolean,
      default: false,
    },
    coeficienteCalculo: {
      type: Number,
      default: null,
    },
  },
  components: {
    CardInfo,
    PopoverLista,
    InputMoney,
    ApuracaoAcaoDetalhesApuracaoRateio,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),

      solicitanteFluxo: false,
      bloquearBotaoExportacao: false,
      exibirPopover: false,

      campos: [
        { campo: 'valorAcao', label: this.$tc('label.valor_acao', 1) },
        { campo: 'formaBonificacao', label: this.$tc('label.verba', 1) },
        { campo: 'dtaInicio', label: this.$tc('label.inicio', 1) },
        { campo: 'dtaFim', label: this.$tc('label.fim', 1) },
        { campo: 'calculoBonificacao', label: this.$tc('label.calculo_bonificacao', 1) },
        { campo: 'baseCalculo', label: this.$tc('label.base_calculo', 1) },
        { campo: 'tipoPagamento', label: this.$tc('label.tipo_pagamento', 1) },
        { campo: 'calculoApuracao', label: this.$tc('label.calculo_apuracao', 1) },
        { campo: 'periodoApuracao', label: this.$tc('label.periodo_apuracao', 1) },
        { campo: 'nomeUnidadeMedida', label: this.$tc('label.unidade_medida', 1) },
        { campo: 'valorBonificacao', label: this.$tc('label.forma_bonificacao', 1) },
        { campo: 'descontos', label: this.$tc('label.desconto', 1) },
        { campo: 'valorBase', label: this.$tc('label.valor_base', 1) },
      ],
      camposPopover: [
      ],
      menu: false,
      item: 'attach_money',
      items: ['attach_money'],
      acaoFormatada: {},
      valorAtual: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
      'getAcaoMetadado',
    ]),
    ...generateComputed('APU_ACAO', [
      'canEdit',
    ]),
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    somenteLeitura() {
      return !this.podeEditar
        || (!this.statusEmCadastro && !this.acao.statusApuracao === 'AGUARDANDO_APURACAO');
    },
    podeEditar() {
      return (this.canEdit && !this.isContrato) || (this.canAccessCRUD && this.isContrato);
    },
    podeAlterarValores() {
      return this.podeEditar && (this.solicitanteFluxo || this.isContrato)
        && (this.acao.statusApuracao === 'AGUARDANDO_APURACAO' || this.acao.statusApuracao === 'EM_ANALISE')
        && !this.acao.isFluxoParalelo;
    },
    podeAlterarVlrPgto() {
      return this.podeEditar
        && this.configuracao.passo5
        && this.configuracao.passo5.apuracao
        && this.configuracao.passo5.apuracao.indEdicaoValorPagamento
        && (this.acao.statusApuracao === 'AGUARDANDO_APURACAO'
          || this.acao.statusApuracao === 'EM_ANALISE')
        && (this.solicitanteFluxo || this.isContrato);
    },
    podeAlterarVlrRealizado() {
      return this.podeEditar
        && this.configuracao.passo5
        && this.configuracao.passo5.apuracao
        && (this.configuracao.passo5.apuracao.indEdicaoValorRealizado
          || this.configuracao.passo5.apuracao.indPreenchimentoValorRealizado)
        && (this.acao.statusApuracao === 'AGUARDANDO_APURACAO'
          || this.acao.statusApuracao === 'EM_ANALISE')
        && (this.solicitanteFluxo || this.isContrato);
    },
    statusEmCadastro() {
      return this.acao.statusApuracao === 'EM_CADASTRO';
    },
    habilitarJustificativa() {
      return this.configuracao
        && this.configuracao.passo5
        && this.configuracao.passo5.apuracao
        && this.configuracao.passo5.apuracao.indJustificativaAlterarValor
        && this.acao.vlrConfApuracao !== this.acao.vlrConfApuracaoOriginal
        && this.acaoFormatada.indJustificativaAlterarValor;
    },
    calculoVolume() {
      if (this.acao.calculoApuracao
          && (this.acao.calculoApuracao === 'SELLIN_VOLUME'
            || this.acao.calculoApuracao === 'SELLOUT_VOLUME')) {
        return true;
      }
      return false;
    },
    calculoValor() {
      if (this.acao.calculoApuracao
          && (this.acao.calculoApuracao === 'SELLIN_VALOR'
            || this.acao.calculoApuracao === 'SELLOUT_VALOR')) {
        return true;
      }
      return false;
    },
    calculoPercentual() {
      if (this.acao.calculoApuracao
          && this.acao.calculoApuracao === 'SELLIN_PERCENTUAL') {
        return true;
      }
      return false;
    },
    calculoQuantidade() {
      return this.acao.calculoApuracao
          && (this.acao.calculoApuracao === 'QUANTIDADE');
    },
    calculoAutomatico() {
      return this.calculoQuantidade;
    },
    metaPorQuantidade() {
      return this.configuracao
        && this.configuracao.passo5
        && this.configuracao.passo5.apuracao.template === 'META_POR_QUANTIDADE';
    },
    unidadeQuantidade() {
      return this.metaPorQuantidade
        || (this.configuracao
          && this.configuracao.passo5
          && this.configuracao.passo5.apuracao.template === 'META_N/A'
          && this.configuracao.passo5.apuracao.indPreenchimentoValorRealizado);
    },
    verbaPercentual() {
      if (this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'PERCENTUAL') {
        return true;
      }
      return false;
    },
    possuiMemoriaCalculo() {
      return this.acaoFormatada && this.acaoFormatada.possuiMemoriaCalculo;
    },
    exibeIconeRateio() {
      return this.vinculoMultiplasContas;
    },
    vinculoMultiplasContas() {
      return this.acaoFormatada && this.acaoFormatada.qtdContasVinculadas > 1;
    },
    verbaVariavel() {
      if (this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'VARIAVEL') {
        return true;
      }
      return false;
    },
  },
  watch: {
    acao(val) {
      this.bloquearBotaoExportacao = false;
      if (val && val.statusApuracao && val.idApuracao) {
        this.verificarUsuarioSolicitante(this.recuperaId(val.idApuracao), val.statusApuracao);
      }
    },
  },
  methods: {
    convertToMoney,
    ...mapActions('metadados', [
      'setProdutoMetadado',
      'setAcaoMetadado',
    ]),
    mudancaJustificativaAlteracaoValor() {
      this.acaoFormatada.indJustificativaAlterarValor = true;
      if (this.acao.vlrConfApuracao === this.acao.vlrConfApuracaoOriginal) {
        this.$emit('ApuracaoDetalhes__AlteracaoJustificativa', '');
      }
    },
    mudancaJustificativa(event) {
      this.$emit('ApuracaoDetalhes__AlteracaoJustificativa', event);
    },
    verificarExistenciaNotas() {
      this.bloquearBotaoExportacao = true;

      const params = {
        idApuracao: this.recuperaId(this.acao.idApuracao),
      };

      this.apuracaoAcaoListagemResource.verificarDadosExportacao(params, params)
        .then((res) => {
          if (res.body) {
            this.exportar();
          } else {
            this.$toast(this.$t('message.sem_dados_exportacao_apuracao'));
          }
          this.bloquearBotaoExportacao = false;
        });
    },
    exportar() {
      const params = {
        id_apuracao: this.recuperaId(this.acao.idApuracao),
      };
      exportacao.exportar(null, 'notas_sellin_memoria_apuracao', this, params);
    },
    verificarUsuarioSolicitante(idApuracao, status) {
      if (status === 'AGUARDANDO_APURACAO' || status === 'EM_ANALISE') {
        this.workflowApuracaoAcaoResource.solicitante({ idApuracao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
          });
      }
    },
    listaCampos() {
      const campos = [].concat(this.campos);
      if (this.configuracao.passo3) {
        const { listaProduto, listaDinamica } = this.configuracao.passo3;
        const camposApuracao = listaProduto.filter((p) => p.fluxos.includes('PLANEJAMENTO') && !p.excecao);
        camposApuracao.forEach((a) => campos.push({
          campo: a.label,
          extensao: !a.indProduto,
          label: a.indProduto ? a.label[0].toUpperCase() + a.label.slice(1)
            : this.montaLabelExtensao(a.label),
        }));
        if (listaDinamica.length > 0) {
          const listaAuxDinamica = listaDinamica.filter((p) => p.fluxos.includes('APURACAO'));
          listaAuxDinamica.forEach((a) => campos.push({
            campo: a.label,
            extensao: !a.indProduto,
            label: this.montaLabelCampoDinamico(a.label),
          }));
        }
      }
      return campos;
    },
    listaCamposPopover() {
      const campos = [].concat(this.camposPopover);
      if (this.configuracao.passo3) {
        const { listaProduto } = this.configuracao.passo3;
        const camposApuracao = listaProduto.filter((p) => p.fluxos.includes('PLANEJAMENTO') && p.excecao);
        camposApuracao.forEach((a) => campos.push({ campo: a.label, excecao: true }));
      }
      return campos;
    },
    formatarObjeto() {
      this.acaoFormatada = { ...this.acao };
      this.valorAtual = this.acao.vlrConfApuracao;
      if (this.acao && this.acao.valorAcao) {
        this.acaoFormatada.valorAcao = convertToMoney(this.acao.valorAcao);
      }

      if (this.verbaVariavel && !this.acao.valorRealizado) {
        this.acao.vlrConfApuracao = convertToMoney(0);
      }

      this.acaoFormatada.vlrConfApuracao = convertToMoney(this.acao.vlrConfApuracao);
      this.acaoFormatada
        .vlrConfApuracaoOriginal = convertToMoney(this.acao.vlrConfApuracaoOriginal);

      if (this.acaoFormatada.valorBonificacao) {
        if (this.acao.formaBonificacao && this.verbaPercentual) {
          this.acaoFormatada.valorBonificacao = convertToPercent(this.acao.valorBonificacao);
        } else {
          this.acaoFormatada.valorBonificacao = convertToMoney(this.acao.valorBonificacao);
        }
      }

      if (!this.acao.calculoApuracao || this.calculoValor) {
        this.acaoFormatada.valorRealizado = convertToMoney(this.acao.valorRealizado);
        this.acaoFormatada
          .valorRealizadoOriginal = convertToMoney(this.acao.valorRealizadoOriginal);
      } else if (this.calculoVolume) {
        this.acaoFormatada.valorRealizado = `${this.acao.valorRealizado} ${this.acao.siglaUnidadeMedida}`;
        this.acaoFormatada.valorRealizadoOriginal = `${this.acao.valorRealizadoOriginal} ${this.acao.siglaUnidadeMedida}`;
      } else if (this.calculoPercentual) {
        this.acaoFormatada.valorRealizado = `${this.acao.valorRealizado} %`;
        this.acaoFormatada.valorRealizadoOriginal = `${this.acao.valorRealizadoOriginal} %`;
      } else {
        this.acaoFormatada.valorRealizado = this.acao.valorRealizado;
        this.acaoFormatada.valorRealizadoOriginal = this.acao.valorRealizadoOriginal;
      }

      if (this.acao && this.acao.periodoApuracao) {
        this.acaoFormatada.periodoApuracao = capitalize(this.acao.periodoApuracao);
      }

      if (this.acao && this.acao.calculoBonificacao) {
        this.acaoFormatada.calculoBonificacao = this.$tc(`label.${this.acao.calculoBonificacao.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.baseCalculo) {
        this.acaoFormatada.baseCalculo = this.$tc(`label.${this.acao.baseCalculo.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.calculoApuracao) {
        this.acaoFormatada.calculoApuracao = this.$tc(`label.${this.acao.calculoApuracao.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.tipoPagamento) {
        this.acaoFormatada.tipoPagamento = this.$tc(`configuracao.TIPO_PAGAMENTO.${this.acao.tipoPagamento.toUpperCase()}`, 1);
      }
      if (this.acao && this.acao.formaBonificacao) {
        this.acaoFormatada.formaBonificacao = this.$tc(`label.${this.acao.formaBonificacao.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.descontos) {
        this.acaoFormatada.descontos = '';
        const listaDescontos = this.acao.descontos.split(';');
        listaDescontos.forEach((p, i) => {
          this.acaoFormatada.descontos = this.acaoFormatada.descontos.concat(`${this.$tc(`label.${p.toLowerCase()}`, 1)}`);
          if (i < (listaDescontos.length - 1)) {
            this.acaoFormatada.descontos = this.acaoFormatada.descontos.concat(';');
          }
        });
      }

      if (this.acao && this.acao.valorBase) {
        this.acaoFormatada.valorBase = convertToMoney(this.acao.valorBase);
      }

      this.listaCampos().filter((c) => c.extensao).forEach((c) => {
        if (this.acao && this.acao[c.campo]) {
          this.acaoFormatada[c.campo] = this.acao[c.campo].nomExtensao;
          if (!this.acaoFormatada[c.campo]) {
            if (Array.isArray(this.acao[c.campo])) {
              this.acaoFormatada[c.campo] = '';
              this.acao[c.campo].forEach((o, i) => {
                this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(o.nomExtensao);
                if (i < (this.acao[c.campo].length - 1)) {
                  this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(';');
                }
              });
            } else if (['inicio_vigencia', 'fim_vigencia'].includes(c.campo)) {
              this.acaoFormatada[c.campo] = formatDateDDMMYYYY(this.acao[c.campo]);
            } else {
              this.acaoFormatada[c.campo] = this.acao[c.campo].nomExtensao || this.acao[c.campo];
            }
          }
        }
      });

      this.listaCamposPopover().filter((c) => c.excecao).forEach((c) => {
        if (this.acao && this.acao[c.campo]) {
          this.exibirPopover = true;
          this.acaoFormatada[c.campo] = this.acao[c.campo].nomExtensao;
          if (!this.acaoFormatada[c.campo]) {
            if (Array.isArray(this.acao[c.campo])) {
              this.acaoFormatada[c.campo] = '';
              this.acao[c.campo].forEach((o, i) => {
                this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(o.nomExtensao);
                if (i < (this.acao[c.campo].length - 1)) {
                  this.acaoFormatada[c.campo] = this.acaoFormatada[c.campo].concat(';');
                }
              });
            } else {
              this.acaoFormatada[c.campo] = this.acao[c.campo].nomExtensao || this.acao[c.campo];
            }
          }
        }
      });
    },
    montaLabelExtensao(campo) {
      const metadado = Object.values(this.getProdutoMetadado.mapaEntidades)
        .filter((dep) => dep.label === campo)[0];
      return metadado.entidadeEstrangeira;
    },
    montaLabelCampoDinamico(campo) {
      const metadado = this.getAcaoMetadado.mapaCamposDinamicos;
      return metadado[campo].nomCampo;
    },
    headers() {
      return [
        {
          text: this.$t('label.meta_de'), value: 'meta_de', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'meta_ate', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'), value: 'bonificacao_pagamento', sortable: false, align: 'center',
        },
      ];
    },
    exibirMeta(valor) {
      if (this.calculoVolume && !this.unidadeQuantidade) {
        return convertToNumberFormat(valor);
      }
      if (this.calculoPercentual && !this.unidadeQuantidade) {
        return convertToPercent(valor);
      }
      if (this.calculoValor && !this.unidadeQuantidade) {
        return convertToMoney(valor);
      }
      return convertToNumberFormat(valor, {
        precision: 2,
      });
    },
    exibirPagamento(valor) {
      if (this.verbaPercentual) {
        return convertToPercent(valor);
      }
      return convertToMoney(valor);
    },
    validarForm() {
      return this.$refs.form.validate();
    },
    validaSaldoContaUnica() {
      const { idApuracao, vlrConfApuracao } = this.acao;
      return this.apuracaoAcaoResource
        .validaSaldoContaUnica({ idApuracao: this.recuperaId(idApuracao), valorAlterado: vlrConfApuracao })
        .then((res) => {
          const validacao = res.data;
          const { suficiente } = validacao;

          if (!suficiente) {
            this.$emit('ApuracaoDetalhes__AusenciaSaldo', {
              periodo: validacao.periodo,
              saldo: getMoney(validacao.saldo),
            });
          }

          return suficiente;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    valorMenorQueValorUtilizado() {
      return this.acao.vlrConfApuracao < this.acao.vlrUtilizado;
    },
    temValorUtilizado() {
      return this.acao.vlrUtilizado && this.acao.vlrUtilizado > 0;
    },
    salvar(valoresPendentes) {
      const parametros = {};
      parametros.valorRealizado = this.acao.valorRealizado;
      parametros.vlrConfApuracao = this.acao.vlrConfApuracao;
      parametros.justificativaAlteracaoValorPagamento = this.justificativaAlteracaoValorPagamento;
      parametros.valorRealizadoOriginal = this.valorAtual;
      parametros.id = this.recuperaId(this.acao.idApuracao);

      if (valoresPendentes) {
        parametros.valoresPendentes = valoresPendentes;
      }

      this.apuracaoAcaoResource.atualizar(parametros)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.acaoFormatada.vlrConfApuracao = this.acao.vlrConfApuracao;
          this.valorAtual = this.acao.vlrConfApuracao;
          this.$emit('ApuracaoDetalhes__ApuracaoAlterada', this.acao.vlrConfApuracao);
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
    exibeRateioValores() {
      this.$refs.modalRateio
        .exibirModal((valoresPendentes) => this.salvar(valoresPendentes), true);
    },
    alterarValorRecebimento() {
      this.acaoFormatada.indJustificativaAlterarValor = false;
      if (!this.acao.valorRealizado) {
        this.acao.vlrConfApuracao = 0;
      } else if (this.configuracao.passo5.apuracao.indPreenchimentoValorRealizado && this.configuracao.passo5.apuracao.indAgrupamentoCategoriaKpi) {
        this.acao.vlrConfApuracao = this.acao.valorBonificacao * this.acao.valorRealizado;
      } else {
        const valorBonificacao = this.buscarValorBonificacaoMeta(this.acao.valorRealizado);
        this.acao.vlrConfApuracao = ((this.acao.valorBase / 100) * valorBonificacao);
        if (this.configuracao.passo5.apuracao.template === 'META_POR_QUANTIDADE') {
          if (this.acao.formaBonificacao === 'PERCENTUAL') {
            this.acao.vlrConfApuracao = ((this.acao.valorRealizado / 100) * valorBonificacao).toFixed(2);
          } else {
            this.acao.vlrConfApuracao = valorBonificacao;
          }
        } else if (this.acao.formaBonificacao === 'PERCENTUAL') {
          this.acao.vlrConfApuracao = ((this.acao.valorBase / 100) * valorBonificacao).toFixed(2);
        } else {
          this.acao.vlrConfApuracao = valorBonificacao;
        }
      }
    },
    buscarValorBonificacaoMeta(valor) {
      let valorBonificacao = 0;
      if (this.acao.meta) {
        if (this.acao.valorRealizado >= this.acao.meta) {
          return this.acao.valorBonificacao;
        }
        return 0;
      }
      if (Math.max(...this.acao.metas.map((meta) => meta.vlrMetaAte)) < valor) {
        return Math.max(...this.acao.metas.map((meta) => meta.vlrBonificacao));
      }
      const menorMeta = this.acao.metas.find((meta) => meta.vlrMetaDe === 0);
      if (!!menorMeta && valor <= 0) {
        return menorMeta.vlrBonificacao;
      }
      this.acao.metas.forEach((meta) => {
        if (numberBetweenValues(valor, meta.vlrMetaDe, meta.vlrMetaAte)) {
          valorBonificacao = meta.vlrBonificacao;
        }
      });
      return valorBonificacao;
    },
    recuperaId(id) {
      if (!Number.isInteger(id) && id !== undefined) {
        return Number(id.split(',')[0]);
      }
      return id;
    },
  },
  beforeMount() {
    this.setProdutoMetadado({
      resource: this.metadadoResource.listarProduto,
    });
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
  },
};
</script>
<style>
  .select--sem--linha > .v-input__control > .v-input__slot:before {
    width: 0% !important;
  }
  .select--sem--linha > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
</style>
