<template lang="html">
  <div class="px-6">
    <v-card-title primary-title class="card-header px-0 py-2">
      <span v-if="acao.idContrato">{{ $tc('label.contrato', 1)}} :: {{ acao.idContrato }} :: {{ acao.divisao }} :: {{ acao.idAcao }} - {{ acao.idApuracao }} :: {{ acao.tipoAcao }}</span>
      <span v-else>{{ $t('label.apuracao_acao_detalhes_resumo', { ...acao }) }}</span>
      <popover-lista id="popover_acao" :objeto="acaoFormatada" :campos="listaPopover()"/>
      <v-spacer></v-spacer>
      <slot name="depoisTitulo"></slot>
    </v-card-title>

    <div class="body-1 pb-2">
      <div v-if="camposContratoProduto">{{ camposContratoProduto }}</div>
      <div v-if="exibirCampoContrato('descricao') && exibirCampo('descricao')">
        <div v-if="acao.descricaoContrato">{{ `${$t('label.descricao_contrato')}: ${acao.descricaoContrato}` }}</div>
        <div v-if="acao.descricao">{{ `${$t('label.descricao_acao')}: ${acao.descricao}` }}</div>
      </div>
      <div v-else-if="exibirCampoContrato('descricao') && acao.descricaoContrato">{{ acao.descricaoContrato }}</div>
      <div v-else-if="exibirCampo('descricao') && acao.descricao">{{ acao.descricao }}</div>
      <div>{{ `${$t('label.periodo_apuracao')}: ${acao.dtaInicioApuracao} ${$tc('label.a', 2)} ${acao.dtaFimApuracao}` }}</div>
      <div v-if="exibirCampo('periodo_planejamento')">{{ `${$t('label.periodo_planejamento')}: ${acao.dtaInicio} ${$tc('label.a', 2)} ${acao.dtaFim}` }}</div>
      <div v-if="exibirCampo('periodo')">{{ `${$t('label.periodo_planejamento')}: ${acao.dtaInicio} ${$tc('label.a', 2)} ${acao.dtaFim}` }}</div>
      <div>{{ `${$tc('label.responsavel', 1)}: ${acao.responsaveis}` }}</div>
      <div v-if="exibirJustificativaCancelamentoFluxo">{{ $t('message.justificativa_cancelamento_acao', { usuario: acao.usuarioAprovador, justificativa: acao.justificativaApuracao}) }}</div>
      <div v-if="exibirJustificativaCancelamento && acao.statusContrato !== 'ENCERRADO'">{{ $t('message.justificativa_cancelamento_acao_data', { data: formatDatePattern(acao.dtaCancelamento, 'DD-MM-YYYY'), usuario: acao.usuarioCancelamento, justificativa: acao.justificativaCancelamento}) }}</div>
      <div v-if="exibirJustificativaCancelamento && acao.statusContrato === 'ENCERRADO'">{{ $t('message.justificativa_encerramento_contrato_data' , { data: formatDatePattern(acao.dtaCancelamento, 'DD-MM-YYYY'), usuario: acao.usuarioCancelamento, justificativa: acao.justificativaCancelamento}) }}</div>
      <div v-if="acao.dadosResgate != null">
        {{ $t('message.apuracao_resgatada_informacao', { ...JSON.parse(acao.dadosResgate.value) }) }}
      </div>
      <div v-if="acao.dadosResgate != null">
        {{ `${$t('label.data_prorrogacao')}: ${JSON.parse(acao.dadosResgate.value).dta_prorrogacao}`}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PopoverLista from '../../shared-components/PopoverLista';
import { formatDatePattern } from '../../common/functions/date-utils';

export default {
  components: {
    PopoverLista,
  },
  props: {
    acao: Object,
    configuracao: Object,
    configuracaoContrato: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      acaoFormatada: {},
      exibirJustificativaCancelamentoFluxo: false,
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getContratoMetadado',
    ]),
    camposContratoProduto() {
      if (this.configuracaoContrato.passo2) {
        const { listaProduto } = this.configuracaoContrato.passo2;
        return listaProduto
          .filter((p) => p.fluxos.includes('APURACAO') && this.valorCampoProduto(p.label))
          .map((p) => this.valorCampoProduto(p.label))
          .join(' / ');
      }
      return '';
    },
    exibirJustificativaCancelamento() {
      return this.acao.statusApuracao === 'CANCELADO'
          && this.acao.justificativaCancelamento && this.acao.usuarioCancelamento;
    },
  },
  methods: {
    formatDatePattern,
    ...mapActions('metadados', [
      'setAcaoMetadado',
      'setContratoMetadado',
    ]),
    valorCampoProduto(campo) {
      return this.acao[campo]
          && this.acao[campo].nomExtensao
        ? this.acao[campo].nomExtensao
        : this.acao[campo];
    },
    exibirCampo(campo) {
      if (this.configuracao.passo3) {
        const { listaPadrao } = this.configuracao.passo3;
        return !!listaPadrao.filter((p) => p.fluxos.includes('APURACAO') && p.label === campo).length;
      }
      return false;
    },
    exibirCampoContrato(campo) {
      if (this.configuracaoContrato.passo2) {
        const { listaPadrao } = this.configuracaoContrato.passo2;
        return !!listaPadrao.filter((p) => p.fluxos.includes('APURACAO') && p.label === campo).length;
      }
      return false;
    },
    listaPopover() {
      const lista = [];
      if (this.exibirCampo('status')) {
        lista.push({ campo: 'status' });
      }
      if (this.exibirCampo('unidadenegocio')) {
        lista.push({ campo: 'unidadeNegocio', label: this.$tc('label.unidade_negocio', 1) });
      }

      if (this.configuracao.passo3) {
        const { listaDinamica } = this.configuracao.passo3;
        const camposApuracao = listaDinamica.filter((p) => p.fluxos.includes('APURACAO'));
        camposApuracao.forEach((a) => lista.push({
          campo: a.label,
          label: this.montaLabelCampoDinamico(a.label),
        }));
      }

      if (this.configuracaoContrato.passo2) {
        const { listaDinamica } = this.configuracaoContrato.passo2;
        const camposApuracao = listaDinamica.filter((p) => p.fluxos.includes('APURACAO'));
        camposApuracao.forEach((a) => lista.push({
          campo: a.label,
          label: this.montaLabelCampoDinamicoContrato(a.label),
        }));
      }

      return lista.filter((x) => !(this.acaoFormatada[x.campo] === null
          || this.acaoFormatada[x.campo] === ''
          || this.acaoFormatada[x.campo] === undefined));
    },
    formatarObjeto() {
      this.acaoFormatada = { ...this.acao };
      if (this.acao && this.acao.statusApuracao) {
        this.acaoFormatada.status = this.$tc(`status_entidade.${this.acao.statusApuracao.toLowerCase()}`, 1);
      }
      if (this.acaoFormatada) {
        this.exibirJustificativaCancelamentoFluxo = this.acaoFormatada.statusFluxo === 'CANCELADO'
            && this.acaoFormatada.justificativaApuracao && this.acaoFormatada.usuarioAprovador;
      }
    },
    montaLabelCampoDinamico(campo) {
      const metadado = this.getAcaoMetadado.mapaCamposDinamicos;
      return (metadado[campo] || {}).nomCampo;
    },
    montaLabelCampoDinamicoContrato(campo) {
      const metadado = this.getContratoMetadado.mapaCamposDinamicos;
      return (metadado[campo] || {}).nomCampo;
    },
  },
  beforeMount() {
    this.setAcaoMetadado({
      resource: this.metadadoResource.definicaoAcao,
    });
    this.setContratoMetadado({
      resource: this.metadadoResource.definicaoContrato,
    });
  },
};
</script>
