<template>
  <div>
    <v-container pa-0 fluid v-if="breadcrumbs.length > 0">
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-col cols="12" >
          <v-breadcrumbs :items="breadcrumbs" divider="/">
            <v-breadcrumbs-item
              slot="item"
              slot-scope="{ item }"
              :to="item.to"
              :key="item.to"
              :disabled="item.disabled"
              @click.native="navegarBreadcrumbs(item)">
                {{ item.text }}
            </v-breadcrumbs-item>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="Nivel3">
      <div class="text-xs-center Paginacao_Detalhes" v-if="apuracoes.length > 1">
        <v-pagination
          v-model="page"
          class="py-2"
          :length="apuracoes.length"
          :total-visible="totalVisible"
          >
        </v-pagination>
        <v-divider></v-divider>
      </div>

      <v-row flex align="start">
        <v-col cols="12">
          <apuracao-acao-detalhes-resumo
            :acao="acao"
            :configuracao="configuracao"
            :configuracao-contrato="configuracaoContrato">
            <div slot="depoisTitulo">
              <v-row justify="end" align="end">
                <apuracao-contrato-cancelar
                  v-if="podeCancelar(acao)"
                  :apuracao="acao"
                  @ApuracaoContratoCancelar__recarregar="recarregar">
                </apuracao-contrato-cancelar>
                <apuracao-alterar-data-fim
                  v-if="indAlterarDataFimApuracao"
                  exibir-tooltip
                  :apuracao="acao"
                  @ApuracaoDetalhes__alteradoDataFim="data => alterarDataFimApuracao(acao, data)"/>
                <apuracao-contrato-acao-concluir
                   ref="apuracaoContratoConcluir"
                   :perguntarProximo="page < apuracoes.length"
                   :apuracao="acao"
                   :concluir-automaticamente="false"
                   @ApuracaoContratoAcaoConcluir_finalizar="salvar(true)"
                   @ApuracaoContratoAcaoConcluir_irProximo="page++"
                   @ApuracaoContratoCancelar__recarregar="recarregar"/>
                <v-tooltip bottom v-if="acao.jaIntegrado">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="ma-0" icon @click="imprimir">
                      <v-icon>print</v-icon>
                    </v-btn>
                  </template>
                  <div>
                    {{ $t('label.exportar') }} {{ $tc('label.nota_debito', 1) }}
                  </div>
                </v-tooltip>
              </v-row>
            </div>

          </apuracao-acao-detalhes-resumo>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-expansion-panels v-model="panel" class="Painel_Detalhes_Acao" multiple accordion>
        <apuracao-acao-detalhes-origem :acao="acao"/>
        <apuracao-acao-detalhes-apuracao ref="detalhesApuracao"
                                         :acao="acao"
                                         :configuracao="configuracao"
                                         :possui-coeficiente-calculo="mostrarCoeficienteCalculo"
                                         :coeficiente-calculo="coeficienteCalculo"
                                         :is-contrato="isContrato"
                                         :justificativa-alteracao-valor-pagamento="justificativaAlteracaoValorPagamento"
                                         :justificativa-alteracao-valor-Base="justificativaAlteracaoValorBase"
                                         @ApuracaoDetalhes__AlteracaoJustificativa="justificativaAlteracaoValorPagamentoAlterada"
                                         @ApuracaoDetalhes__AlteracaoJustificativaValorBase="justificativaAlteracaoValorBaseAlterada"
                                         @ApuracaoDetalhes__alterado="apuracaoAlterada"/>
      </v-expansion-panels>
      <v-col style="text-align:right">
        <v-btn dark
          color="primary"
          v-if="podeSalvar(acao)"
          @click="salvar(false)">{{ $t('label.salvar') }}
        </v-btn>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApuracaoAcaoFluxo from '@/spa/apuracao-acao/ApuracaoAcaoFluxo';
import ApuracaoAcaoDetalhesApuracao from '@/spa/apuracao-acao/ApuracaoAcaoDetalhesApuracao';
import ApuracaoAcaoDetalhesResumo from '../apuracao-acao/ApuracaoAcaoDetalhesResumo';
import ApuracaoAcaoDetalhesOrigem from '../apuracao-acao/ApuracaoAcaoDetalhesOrigem';
import ApuracaoContratoAcaoConcluir from './ApuracaoContratoAcaoConcluir';
import getBasePath from '../../common/functions/api-resource';
import exportacao from '../../common/resources/downloads';
import ApuracaoAcaoDetalhesFoco from '../apuracao-acao/ApuracaoAcaoDetalhesFoco';
import ApuracaoContratoAcaoConcluirIndustria from './finalizar-lote/ApuracaoContratoAcaoConcluirIndustria';
import ApuracaoAlterarDataFim from './ApuracaoAlterarDataFim';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import { formatDatePattern } from '../../common/functions/date-utils';
import ApuracaoContratoCancelar from './ApuracaoContratoCancelar';
import { buscarApuracoes } from '../../common/resources/apuracao/apuracao-acao-listagem';

export default {
  name: 'ApuracaoContratoDetalhesCard',
  components: {
    ApuracaoContratoCancelar,
    ApuracaoAlterarDataFim,
    ApuracaoContratoAcaoConcluirIndustria,
    ApuracaoAcaoDetalhesFoco,
    ApuracaoContratoAcaoConcluir,
    ApuracaoAcaoFluxo,
    ApuracaoAcaoDetalhesResumo,
    ApuracaoAcaoDetalhesOrigem,
    ApuracaoAcaoDetalhesApuracao,
  },
  data() {
    return {
      apuracaoAcaoListagemResource: this.$api.apuracaoAcaoListagem(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      planejamentoContratoListagemResource: this.$api.planejamentoContratoListagem(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      configuracaoContratoResource: this.$api.planejamentoContratoConfiguracao(this.$resource),
      apuracaoAcaoResource: this.$api.apuracaoAcaoEdicao(this.$resource),

      page: -1,
      panel: [0, 1],
      acao: {},
      configuracao: {},
      configuracaoContrato: {},
      apuracoes: [],
      objetoAcao: null,
      idContrato: null,
      coeficienteCalculo: null,
      breadcrumbs: [],
      valorAtual: null,
      justificativaAlteracaoValorPagamento: null,
      justificativaAlteracaoValorBase: null,
      numeroNd: null,
    };
  },
  watch: {
    page: {
      handler() {
        this.objetoAcao = this.apuracoes[this.page - 1];
        this.objetoAcao.idContrato = this.idContrato;
        this.setFiltroApuracaoContratoNivel3(this.objetoAcao);
        this.carregarApuracao(this.objetoAcao.codApuracao);
      },
    },
  },
  computed: {
    totalVisible() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 5;
      }
      return 12;
    },
    ...generateComputed('APU_CONTR', [
      'canAccessCRUD',
    ]),
    ...generateComputed('ACESSO_CLIENTE', [
      'getAllRoles',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isUsuarioFuncionario() {
      return this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    isContrato() {
      return !!this.idContrato;
    },
    campoCliente() {
      if (!this.configuracaoContrato.passo2) {
        return '';
      }
      const { listaCliente } = this.configuracaoContrato.passo2;
      const { campoCliente } = (listaCliente || []).find((el) => el.label === 'campo_cliente') || {};
      return (campoCliente || {}).label || '';
    },
    mostrarCoeficienteCalculo() {
      return this.possuiCoeficienteCalculoHabilitado
          && this.visualizaCoeficienteCalculoApuracao;
    },
    possuiCoeficienteCalculoHabilitado() {
      return this.configuracaoContrato
          && this.configuracaoContrato.passo3
          && this.configuracaoContrato.passo3.coeficienteCalculo;
    },
    visualizaCoeficienteCalculoApuracao() {
      if (this.configuracaoContrato.passo2
          && this.configuracaoContrato.passo2.listaPadrao) {
        const listaCampo = this.configuracaoContrato.passo2.listaPadrao
          .filter((c) => c.label === 'coeficiente_calculo');
        if (listaCampo.length > 0) {
          const { fluxos } = listaCampo[0];
          return fluxos && fluxos.indexOf('APURACAO') > -1;
        }
        return false;
      }
      return false;
    },
    indAlterarDataFimApuracao() {
      return !!this.getAllRoles.filter((el) => el.startsWith('ALTERAR_DTA_FIM_APURACAO')).length
        && this.canAccessCRUD;
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroApuracaoContratoNivel3',
    ]),
    podeCancelar(item) {
      return (item.statusApuracao === 'AGUARDANDO_APURACAO'
        || item.statusApuracao === 'APURACAO_PREVIA')
        && this.canAccessCRUD
        && this.isUsuarioFuncionario;
    },
    podeSalvar(item) {
      return (item.status === 'AGUARDANDO_APURACAO' || item.statusApuracao === 'AGUARDANDO_APURACAO')
        && (item.vlrConfApuracao > 0 || item.recebimento) && this.canAccessCRUD;
    },
    apuracaoAlterada() {
      this.$refs.apuracaoConcluir.buscarDadosApuracao();
    },
    justificativaAlteracaoValorPagamentoAlterada(novoTexto) {
      this.justificativaAlteracaoValorPagamento = novoTexto;
    },
    buscarJustificativaAlteracaoValor(idApuracao) {
      this.apuracaoAcaoResource.buscarJustificativaAlteracaoValor({ idApuracao })
        .then((res) => {
          this.justificativaAlteracaoValorPagamento = res.data.justificativa;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarJustificativaAlteracaoValorBase(idApuracao) {
      this.apuracaoAcaoResource.buscarJustificativaAlteracaoValorBase({ idApuracao })
        .then((res) => {
          this.justificativaAlteracaoValorBase = res.data.justificativa;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    alterarDataFimApuracao(acao, data) {
      acao.dtaFimApuracao = formatDatePattern(data, 'YYYY-MM-DD');
    },
    carregarAcao(idAcao) {
      return this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          const { categoria } = this.acao;
          this.acao = {
            ...this.acao,
            ...res.data.passoGeral.mapaExtensoes,
            ...res.data.passoGeral.mapaCamposDinamicos,
            ...res.data.passoComportamento.mapaExcecoes,
            categoria,
          };
          this.acao.meta = res.data.passoComportamento.meta;
        })
        .then(this.carregarContrato)
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    imprimir() {
      const { idApuracao } = this.acao;
      const basePath = getBasePath('job', 'recebimento/relatorio');
      const resource = exportacao(this.$http);

      resource.downloadGet(basePath, {
        param: `?nomeArquivo=${this.numeroNd}`,
        url: `apuracao/${this.recuperaId(idApuracao)}/nota-debito/`,
      }).catch(() => {
        this.$toast('Erro ao exportar. Tente novamente.');
      });
    },
    carregarContrato() {
      if (!this.acao.idContrato) return;
      const { idContrato } = this.acao;
      this.planejamentoContratoListagemResource.buscarContrato({ idContrato })
        .then((res) => {
          this.acao = {
            ...this.acao,
            ...res.data.passoGeral.mapaExtensoes,
            ...res.data.passoGeral.mapaCamposDinamicos,
            descricaoContrato: res.data.passoGeral.descricao,
            dtaVigenciaFim: res.data.passoGeral.dtaVigenciaFim,
            dtaVigenciaInicio: res.data.passoGeral.dtaVigenciaInicio,
          };
          this.coeficienteCalculo = res.data.passoGeral.valorBase;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarApuracao(idApuracao) {
      if (!Number.isInteger(idApuracao)) {
        const params = {
          apuracoes_selecionadas: idApuracao.split(','),
        };
        this.buscarJustificativaAlteracaoValor(idApuracao.split(',')[0]);
        buscarApuracoes(params, this.$resource).then((res) => {
          this.acao = { ...res.data };
          this.acao.recebimento = this.acao.valorRecebimento;
          this.valorAtual = this.acao.vlrConfApuracao;
        }).then(() => {
          const idAcao = this.acao.idAcao.split(',')[0];
          Promise.all([
            this.carregarConfiguracao(idAcao),
            this.carregarAcao(idAcao),
            this.carregarConfiguracaoContrato(this.acao.idContrato),
          ]).then(() => {
            this.$refs.detalhesApuracao.formatarObjeto();
          });
        })
          .catch((err) => {
            this.finalizandoBusca();
            this.$error(this, err);
          });
      } else {
        this.buscarJustificativaAlteracaoValor(idApuracao);
        this.apuracaoAcaoListagemResource.buscarApuracao({ idApuracao })
          .then((res) => {
            this.acao = { ...res.data };
            this.acao.recebimento = this.acao.valorRecebimento;
            this.valorAtual = this.acao.vlrConfApuracao;
          })
          .then(() => {
            Promise.all([
              this.carregarConfiguracao(this.acao.idAcao),
              this.carregarAcao(this.acao.idAcao),
              this.carregarConfiguracaoContrato(this.acao.idContrato),
            ]).then(() => {
              this.$refs.detalhesApuracao.formatarObjeto();
            });
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    carregarConfiguracao(idAcao) {
      return this.configuracaoResource.buscarConfigSnapshot({ idAcao: this.recuperaId(idAcao) }).then((res) => {
        this.configuracao = res.data;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarConfiguracaoContrato(idContrato) {
      if (!idContrato) return () => {};
      return this.configuracaoContratoResource.buscarConfigSnapshot({ idContrato })
        .then((res) => {
          this.configuracaoContrato = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    recarregar(novoStatus = null) {
      if (this.$route.params.status != null && novoStatus != null) {
        const params = { ...this.$route.params };
        params.status = novoStatus;
        this.apuracoes = [this.objetoAcao];
        this.$router.push({ name: 'detalharApuracaoContratoStatus', params });
        setTimeout(() => window.location.reload());
        return;
      }
      if (this.$route.params.id) {
        this.carregarApuracao(this.$route.params.id);
      }
    },
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }
      if (item.nivel === 2) {
        this.breadcrumbs.splice(2, 1);
      } else {
        this.breadcrumbs.splice(1, 2);
      }

      this.$router.push({
        name: item.to,
        params: {
          breadcrumbsParam: this.breadcrumbs,
        },
      });
    },
    montaBreadcrumbsNotaDebito() {
      this.breadcrumbs = [{
        text: this.$tc('title.nota_debito', 2),
        to: 'notas-debito-consulta',
        disabled: false,
      }, {
        text: this.$tc('label.detalhe', 1),
        disabled: true,
      }];
    },
    salvar(concluir = null) {
      const parametros = {};
      parametros.valorRealizado = this.acao.valorRealizado;
      parametros.vlrConfApuracao = this.acao.vlrConfApuracao;
      parametros.justificativaAlteracaoValorPagamento = this.justificativaAlteracaoValorPagamento;
      parametros.justificativaAlteracaoValorBase = this.justificativaAlteracaoValorBase;
      parametros.valorRealizadoOriginal = this.valorAtual;
      parametros.id = this.recuperaId(this.acao.idApuracao);
      if (this.acao.indAgrupamentoCategoriaKpi) {
        parametros.indApuracaoAgrupada = this.acao.indAgrupamentoCategoriaKpi;
      }
      this.apuracaoAcaoResource.atualizar(parametros)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.valorAtual = this.acao.vlrConfApuracao;
          if (concluir) {
            this.$refs.apuracaoContratoConcluir.concluir(true);
          }
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    recuperaId(id) {
      return Number.isInteger(id) ? id : Number(id.split(',')[0]);
    },
  },
  beforeMount() {
    this.idContrato = this.$route.params.idContrato;

    this.objetoAcao = {
      codApuracao: this.$route.params.id,
      codAcao: this.$route.params.idAcao,
      idContrato: this.idContrato,
      status: this.$route.params.status || this.acao.statusApuracao,
    };

    this.apuracoes = this.$route.params.apuracoes
      ? this.$route.params.apuracoes
      : [this.objetoAcao];
    this.origem = this.$route.params.origem;
    this.numeroNd = this.$route.params.numero ? this.$route.params.numero : null;

    if (this.$route.params.breadcrumbsParam) {
      this.breadcrumbs = this.$route.params.breadcrumbsParam;
    } else if (this.origem && this.origem === 'NOTA_DEBITO') {
      this.montaBreadcrumbsNotaDebito(this.$route.params.breadcrumbsParam);
    }
  },
  mounted() {
    if (this.objetoAcao.codApuracao) {
      this.setFiltroApuracaoContratoNivel3(this.objetoAcao);
      if (this.apuracoes && this.apuracoes.length > 0) {
        const apuracao = this.apuracoes
          .filter((aa) => aa.codApuracao === this.objetoAcao.codApuracao)[0];
        if (apuracao) {
          this.page = this.apuracoes.indexOf(apuracao) + 1;
        }
      } else {
        this.carregarApuracao(this.objetoAcao.codApuracao);
      }
    }
  },
};
</script>

<style lang="scss">

  .Barra_Breadcrumbs {
    .v-breadcrumbs {
      padding: 0.5em 0 1em 0;
    }
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }
  .Paginacao_Detalhes {
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }
  .Nivel3 .card-header {
    background-color: #fff;
    color: #000;
  }
  .Painel_Detalhes_Acao .v-expansion-panel__header__icon{
    margin-right: 1em;
  }
  .Painel_Detalhes_Acao .v-expansion-panel__header{
    padding:0;
    margin-left: 1.5em;
    min-height: 42px;
  }
</style>
